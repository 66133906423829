<template>
    <div>
        <div class="row">
            <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
                <user-email-verification />                   
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
               <order-list-table />
            </div>
        </div>
    </div>
</template>
<script>

import UserEmailVerification from "@/components/UserEmailVerification.vue"
import OrderListTable from "@/components/order/OrderListTable.vue"

export default {
    components: {
        UserEmailVerification,
        OrderListTable
    },
    data() {
        return {
            isLoading:false,
            result: {
                
            },
        };
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
    },
    created() {
        this.$http.get(`/analytics`)
        .then((response) => {
            if(response.data.success){
                this.result = response.data.data
                this.isLoading = true
            }
        })
    }
};
</script>
